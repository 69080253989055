$font-folder-path: "../fonts/";
$ims-gilroy-bold: "Gilroy-Bold";
$ims-gilroy-regular: "Gilroy-Regular";
$ims-gilroy-light: "Gilroy-Light";
$ims-gilroy-bolditalic: "Gilroy-BoldItalic";

@mixin font-face($fontfamily, $filename, $fontweight: 400) {
  $temp-font-path: $font-folder-path + $filename;

  @font-face {
    font-family: $fontfamily;
    font-weight: $fontweight;
    font-style: normal;
    font-stretch: normal;
    src: url($temp-font-path + ".otf");
  }
}

@include font-face($ims-gilroy-bold, "Gilroy-Bold");
@include font-face($ims-gilroy-regular, "Gilroy-Regular");
@include font-face($ims-gilroy-light, "Gilroy-Light");
@include font-face($ims-gilroy-bolditalic, "Gilroy-BoldItalic");
