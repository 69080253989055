input[type="date"]::-webkit-input-placeholder{
  visibility: hidden !important;
}

input::-webkit-calendar-picker-indicator{
  display: none;
}

input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type=date] {
  -moz-appearance: textfield;
}
