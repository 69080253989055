.MuiTreeItem-label:hover {
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 12px;
}
.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover,
.MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 12px;
}
.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: #dddddd;
  border-radius: 12px;
}
